// /* eslint-disable */
import './scripts/foundation';
import './scripts/inc/functions';
import './scripts/header';
import './scripts/module-jobs-show-more';

$(document).foundation();

/**
 * Counter
 */
$(window).on('scroll', startCounter);

function startCounter() {
  $('.counter').each(function (i, section) {
    if ($(section).isInViewport()) {
      if ($(section).hasClass('js-active')) return;

      $(section).addClass('js-active');
      $(section)
        .find('.counter-item_number')
        .each(function (i, counter) {
          $(counter).countUp();
        });
    } else {
      $(section).removeClass('js-active');
    }
  });
}

startCounter();

/**
 * Add class for section above cover section
 */

let headlineCopy = $('.headline-copy.gradient');
$(headlineCopy).each(function (i, section) {
  let nextSection = $(section).next();
  if ($(nextSection).hasClass('upper-block')) {
    $(section).addClass('add-padding');
  }
});

(function () {
  // clipboard.writeText
  const singleJobPage = $('main.single-job');
  if (!singleJobPage.length) return;

  const copyLinkBtn = $('.copy-link');
  if (!copyLinkBtn.length) return;

  copyLinkBtn.on('click', function (e) {
    e.preventDefault();

    navigator.clipboard.writeText($(this).attr('href'));
  });
})();
