$.fn.isInViewport = function () {
  if ($(this).length) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop() - 200;
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
  return 0;
};

$.fn.countUp = function () {
  var num =
    typeof $(this).attr('data-initial') !== 'undefined' &&
    $(this).attr('data-initial') !== false
      ? $(this).attr('data-initial')
      : 0;
  let decimal = 0;

  if (num === 0) {
    return;
  }

  if (num.indexOf('.') > 0) {
    // if number is Decimal
    decimal = num.toString().split('.')[1].length;
    $(this)
      .prop('Counter', 0.0)
      .animate(
        {
          Counter: $(this).attr('data-initial'),
        },
        {
          duration: 1000,
          step: function (now) {
            $(this).text(parseFloat(now).toFixed(decimal));
          },
          complete: function () {
            $(this).stop(true, true);
          },
        }
      );
  } else if (num.indexOf(',') > 0) {
    num = num.replace(',', '.');
    decimal = num.split('.')[1].length;
    $(this)
      .prop('Counter', 0.0)
      .animate(
        {
          Counter: $(this).attr('data-initial').replace(',', '.'),
        },
        {
          duration: 1000,
          step: function (now) {
            $(this).text(parseFloat(now).toFixed(decimal).replace('.', ','));
          },
          complete: function () {
            $(this).stop(true, true);
          },
        }
      );
  } else {
    decimal = 0;
    $(this)
      .prop('Counter', 0.0)
      .animate(
        {
          Counter: $(this).attr('data-initial'),
        },
        {
          duration: 1000,
          step: function (now) {
            $(this).text(parseFloat(now).toFixed(decimal));
          },
          complete: function () {
            $(this).stop(true, true);
          },
        }
      );
  }
};
