/* global ajaxObj */

(function () {
  const sections = $('section.jobs');
  if (!sections.length) return;

  sections.each(function (_, section) {
    const showMoreWrapper = $(section).find('.show-more-button-wrapper');
    const showMoreButton = $(section).find('.show-more-button');
    const listWrapper = $(section).find('.jobs__list');

    showMoreButton.on('click', function () {
      showMoreButton.attr('disabled');

      $.ajax({
        url: ajaxObj.ajaxUrl,
        type: 'GET',
        data: {
          action: 'get_jobs',
        },
        success: function (resp) {
          if (resp.success) {
            listWrapper.html(resp.data);
          } else {
            console.error('Error loading jobs:', resp.data);
          }
        },
        complete: function () {
          showMoreWrapper.remove();
        },
      });
    });
  });
})();
