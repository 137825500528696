const myHeader = $('.header');
const menuIcon = $('.menu-icon');
const myBody = $('body');

$(window)
  .on('toggled.zf.responsiveToggle', function () {
    menuIcon.toggleClass('is-active');

    lock_unlock_Body();
  })
  .on('changed.zf.mediaquery', function () {
    menuIcon.removeClass('is-active');

    lock_unlock_Body();
  });

/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if (menuIcon.hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});

(function () {
  //===== sticky header ======

  //get height first section
  const firstSection = $('main section');
  let setFirstElementHeight;

  if (firstSection.length > 0) {
    setFirstElementHeight = () =>
      myBody.css('--firstSectionHeight', `${firstSection.outerHeight()}`);
    $(window).on('resize', setFirstElementHeight);
  } else {
    setFirstElementHeight = () => myBody.css('--firstSectionHeight', `10000`);
  }

  $(document).ready(setFirstElementHeight);

  //scroll down/up
  let isHeaderFixed = false;
  $(window).on('scroll', function () {
    let scrolledPixels = document.documentElement.scrollTop;
    const firstSectionHeight = getComputedStyle(document.body).getPropertyValue(
      '--firstSectionHeight'
    );

    if (scrolledPixels > Number(firstSectionHeight)) {
      if (isHeaderFixed === false) {
        myHeader.addClass('sticky-header');
        myHeader.css({
          position: 'fixed',
          transform: 'translateY(-100%)',
          transition: 'transform 1s ease',
          opacity: '0',
        });
        setTimeout(function () {
          myHeader.css({
            transform: 'translateY(0)',
            opacity: '1',
          });
        }, 300);
      }

      isHeaderFixed = true;
    } else {
      myHeader.removeClass('sticky-header');
      myHeader.css('position', 'absolute');

      isHeaderFixed = false;
    }
  });
})();

(function () {
  const firstMenuElementButton = $('.menu li.button').first();
  if (!firstMenuElementButton.length) return;

  firstMenuElementButton.prev().addClass('press-button');
})();

function lock_unlock_Body() {
  if (menuIcon.hasClass('is-active')) {
    myBody.addClass('lock');
    myHeader.addClass('burger-active');
  } else {
    myBody.removeClass('lock');
    myHeader.removeClass('burger-active');
  }
}
